import Icon from '@components/icons'
import { Picture } from '@components/ui'
import { MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import { useState, useEffect } from 'react'
import styles from './Footer.module.css'
import { Text, useUI } from '@components/ui'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'

const CountryModalComp = ({ footerCountry, pageType, locale }) => {
  const { countryModal, setCountryModal } = useUI()
  const [isAuto, setIsAuto] = useState(false)

  const resizeWindow = () => {
    const offsetWidth = document.querySelector('body').offsetWidth
    if (offsetWidth < 768) return setIsAuto(true)
    setIsAuto(false)
  }

  // start_ai_generated
  /**
   * 根据提供的数据构造URL
   */
  const getUrl = (data) => {
    // 从data对象中解构出url和type
    const { url, type } = data
    // 初始化href为空字符串
    let href = ''
    // 构造引用字符串，包含locale变量
    // _pps
    let refStr =
      pageType === 'charger'
        ? `?ref=${countryModal.position}_${locale}`
        : `?ref=${countryModal.position}_pps_${locale}`
    // 如果类型是RSO，直接使用url
    if (type === 'RSO') {
      href = url
      // 如果类型是trusteeship，根据pageType决定url
    } else if (type === 'trusteeship') {
      href = pageType === 'charger' ? url : `${url}/ankerpower`
      // 其他情况下，也根据pageType决定url
    } else {
      href = pageType === 'charger' ? url : `${url}/anker-solix`
    }
    // 返回构造好的href和引用字符串
    return href + refStr
  }
  // end_ai_generated

  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)
    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [])

  return (
    <div
      style={{
        opacity: !countryModal.visible ? 0 : 1,
        pointerEvents: !countryModal.visible ? 'none' : 'auto',
      }}
    >
      <div className={cn(styles.modal_wrap)}>
        <button
          aria-label="close"
          className={styles.close}
          onClick={() =>
            setCountryModal({
              visible: false,
              position: 'footer',
            })
          }
        ></button>
        <div
          className={cn(styles.dialog_wrap, {
            [styles.activeWrap]: countryModal.visible && isAuto,
          })}
        >
          <div className={styles.dialog_content}>
            <button
              aria-label="close"
              className={styles.dialog_close}
              onClick={() =>
                setCountryModal({
                  visible: false,
                  position: 'footer',
                })
              }
            >
              <XMarkIcon className="h-[20px] w-[20px]" />
            </button>
            <div className={styles.content_wrap}>
              <div className={styles.content_title}>{footerCountry?.title}</div>
              <div className={styles.content}>
                {footerCountry?.list_new && (
                  <ul>
                    {footerCountry.list_new.map((regionItem, i) =>
                      regionItem?.map((item, i) => {
                        return item.title ? (
                          <li
                            className="mt-5 mb-2.5 !w-full text-center text-[16px] font-bold leading-[1.7] text-[#333] md:mb-[15px]"
                            key={i + item.title}
                          >
                            {item.title}
                          </li>
                        ) : (
                          <li key={item.id + i}>
                            <a
                              href={getUrl(item)}
                              target={item.target || '_blank'}
                              onClick={() => {
                                let locale = ''
                                try {
                                  const url = new URL(item.url)
                                  locale = url.pathname.replace('/', '')
                                } catch (e) {
                                  locale = item.url.replace('/', '')
                                }
                                Cookies.set(
                                  'locale_action',
                                  JSON.stringify({
                                    locale,
                                    actionType: 'normal',
                                  }),
                                  {
                                    expires: 30,
                                  }
                                )
                                // window.location.href = getUrl(item)
                              }}
                            >
                              <p className="flex items-center gap-[8px]">
                                {item?.iconSrc && (
                                  <Picture
                                    className="w-[24px] h-[24px]"
                                    source={item?.iconSrc}
                                  />
                                )}
                                {item.country}
                              </p>
                              <p>{item.language}</p>
                            </a>
                          </li>
                        )
                      })
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CountryModalComp
